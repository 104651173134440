import Vue from 'vue';
import router from '@/router';

export default {
    install() {
        Vue.prototype.$system = new Vue({
            data() {
                return {
                    user: {
                        Email: '',
                        Id: '',
                        Password: '',
                        isAuthenticated: false,
                        FirstName: '',
                        LastName: '',
                        Type: ''
                    },
                };
            },

            methods: {
                login(user, id, firstName, lastName) {
                    if (user.Email && user.Password && id) {
                        this.user.Email = user.Email;
                        this.user.Password = this.cipher(process.env.NODE_ENV, user.Password);
                        this.user.Id = id;
                        this.user.FirstName = firstName;
                        this.user.LastName = lastName;
                        this.user.isAuthenticated = true;
                        if (router.currentRoute.path !== '/submissions') {
                            router.push('/submissions')
                        }

                        if (user.Type !== 'viewer') {
                            localStorage.Email = this.user.Email;
                            localStorage.Password = this.cipher(process.env.NODE_ENV, user.Password);
                        } else this.user.Type = 'viewer'

                    } else this.logout()
                },
                logout() {
                    if (this.user.Type !== 'viewer') {
                        localStorage.clear();
                    }
                    this.user = {
                        Email: '',
                        Id: '',
                        Password: '',
                        isAuthenticated: false,
                        FitstName: '',
                        LastName: '',
                        Type: ''
                    };
                },
                firstLoading() {
                    setTimeout(() => {
                        if (!this.user.isAuthenticated) {
                            router.push('/')
                            this.logout()
                        }
                    }, 6000)
                },
                cipher(salt, text) {
                    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
                    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
                    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

                    return text
                        .split("")
                        .map(textToChars)
                        .map(applySaltToChar)
                        .map(byteHex)
                        .join("");
                },
                decipher(salt, encoded) {
                    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
                    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
                    return encoded
                        .match(/.{1,2}/g)
                        .map((hex) => parseInt(hex, 16))
                        .map(applySaltToChar)
                        .map((charCode) => String.fromCharCode(charCode))
                        .join("");
                },
                makeToast(variant = null, title, content) {
                    this.$bvToast.toast(`${content}`, {
                        title: `${title}`,
                        variant: variant,
                        solid: true,
                    })
                },
            },

        });
    },
};