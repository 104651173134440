<template>
  <div class="funded-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Search-->
      <div class="row">
        <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Enter search text"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="col-2 col-lg-3 p-0 mb-3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-10 col-lg-1 offset-lg-5 mb-3">
          <div class="flex-end">
            <b-icon-arrow-counterclockwise
              @click="getFunded"
              class="btn__refresh"
              v-b-tooltip.hover
              title="Refresh"
            />
          </div>
        </div>
      </div>
      <!--Funded table-->
      <div v-if="!loading" class="row">
        <b-table
          class="table"
          hover
          striped
          :items="funded"
          :fields="fieldsFunded"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!--Account Name Router Link column-->
          <template #cell(Name)="row">
            <div>
              <template v-if="row.item.Name && row.item.Id">
                <router-link
                  :to="'account/' + row.item.Id"
                  v-b-tooltip.hover
                  title="Account information"
                  class="active-link-table"
                >
                  {{ row.item.Name }}
                </router-link>
              </template>
              <template v-else>
                {{ row.item.Name }}
              </template>
            </div>
          </template>

          <!-- Programs btn-->
          <template #cell(repayments)="row">
            <div>
              <template
                v-if="row.item.repayments && row.item.repayments.length"
              >
                <a
                  @click="row.toggleDetails"
                  class="active-link-table"
                  v-b-tooltip.hover
                  :title="
                    row.detailsShowing ? 'Hide Programs' : 'Show Programs'
                  "
                >
                  {{ row.detailsShowing ? 'Hide' : 'Show' }} Programs
                </a>
              </template>
            </div>
          </template>
          <!--Programs Table-->
          <template #row-details="row">
            <b-table
              class="table"
              hover
              striped
              :items="row.item.repayments"
              :fields="fieldsRepayments"
              responsive
            >
              <template #cell(ISORep)="row">
                <div>
                  <template v-if="row.item.ISORep">
                    {{
                      row.item.ISORep.FirstName ? row.item.ISORep.FirstName : ''
                    }}
                    {{
                      row.item.ISORep.LastName ? row.item.ISORep.LastName : ''
                    }}
                  </template>
                </div>
              </template>
            </b-table>
          </template>
        </b-table>
        <div v-if="!funded.length" class="col-12 flex-center">
          <p class="text-center p-5">No records found.</p>
        </div>
        <!--Table Pagination-->
        <div class="col-12 flex-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './FundedPage.scss'
import FundedService from '@/services/funded/funded.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'FundedPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Funded',
    titleTemplate: '%s | ISO Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Funded',
      backCommissions: [],
      funded: [],
      fieldsFunded: [
        {
          key: 'Name',
          label: 'Account Name',
          sortable: true,
        },
        {
          key: 'DBA',
          label: 'DBA',
          sortable: true,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'repayments',
          label: 'Programs',
          sortable: false,
        },
      ],
      fieldsRepayments: [
        {
          key: 'StartDateFormatted',
          label: 'Start Date',
          sortable: false,
        },
        // {
        //   key: 'name',
        //   label: 'DBA Name',
        //   sortable: true,
        // },
        {
          key: 'ContractNumber',
          label: 'Contract №',
          sortable: true,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'FundedAmount',
          label: 'Funded Amt',
          sortable: true,
        },
        {
          key: 'PaybackAmount',
          label: 'Payback Amt',
          sortable: true,
        },
        {
          key: 'PendingBalance',
          label: 'Pending Balance',
          sortable: true,
        },
        {
          key: 'Commission',
          label: 'Commission',
          sortable: true,
        },
        {
          key: 'CommissionPaid',
          label: 'Commission Paid',
          sortable: true,
        },
        {
          key: 'CommissionBalance',
          label: 'Commission Balance',
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getFunded()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getFunded()
    } else this.$system.firstLoading()
  },
  methods: {
    async getFunded() {
      try {
        this.loading = true
        const response = await FundedService.getFunded(this.$system.user)
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.funded = response.body.data
          if (response.body.isMain) {
            let field = {
              key: 'ISORep',
              label: 'ISO REP',
              sortable: false,
            }
            this.fieldsRepayments.unshift(field)
          }
          this.totalRows = this.funded.length
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
  },
}
</script>
